
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.g-card {
  margin: 1.3em;
  width: 19em;
  padding-bottom: 7em;
}


.g-card-image {
  border-radius: 10px;
  width: 19em;
  height: 30em;
  box-shadow: 0px 0px 3px 1px #ccc;
}


.g-card-info {
  margin-top: 0.63em;
  min-height: 6.3em;
  margin-bottom: 1em;
}


.g-card-title {
  font-size: 24px;
  margin: 0px;
}


.g-card-sub-title {
  font-size: 16px;
  margin: 0px;
}


/* .success-msg {
  color: #57A773;
  padding: 10px 15px;
}


.err-msg {
  color: #EE6352;
  padding: 10px 15px;
} */